import React from 'react';
import styled from "styled-components";
import Separator from "./Separator";
import { FaBuilding, FaPhone, FaUser, FaFacebook } from 'react-icons/fa'; // Importing the phone icon from react-icons/fa
import { FaXTwitter } from 'react-icons/fa6';


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Ajusta la altura según sea necesario */
  background-color: #621132; /* Color de fondo del cuadro */
  border-radius: 10px; /* Borde redondeado */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Sombra */
  margin-top: 50px; /* Añade un margen superior para separarlo de la tabla superior */
`;
const Title = styled.h1`
  width: 100%;
  font-size: 32px;
`;

const Light = styled.span`
  font-weight: 300;
  font-size: 24px;
`;

const Description = styled.p`
  font-size: 25px; /* Tamaño del texto */
  font-weight: bold; /* Negrita */
  color: white; /* Color del texto */
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  margin-top: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  table-layout: fixed;
  border-spacing: 0;
`;

const TableHeader = styled.thead`
  background-color: #f4f4f4;

  th {
    font-weight: bold;
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
    background-color: #e9ecef;
    color: #495057;
    font-size: 18px;
  }
`;

const TableBody = styled.tbody`
  tr {
    :nth-child(even) {
      background-color: #f9f9f9;
    }
    
    :hover {
      background-color: #f1f1f1;
    }
  }

  td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 16px;
  }
  td {
    white-space: pre-line;
  }
`;

const Icon = styled.div`
  font-size: 24px;
  color: ${props => props.color || '#4CAF50'};
`;

const QuejasYDenuncias = () => {
  return (
    <>
      <Title id="quejas">
      Mecanismo de Atención Ciudadana <Light>(MAC)</Light>
      </Title>
      <Separator />
      <Wrapper>
        <Description>
        Quejas y denuncias
        </Description>
      </Wrapper>
      <TableWrapper>
        <Table>
          <TableHeader>
            <tr>
              <th>Mecanismo<Icon color="#285C4D"><FaBuilding /></Icon></th>
              <th>Casos que atiende<Icon color="#285C4D"><FaUser /></Icon></th>
              <th>Medios de recepción <Icon color="#285C4D"><FaPhone /></Icon></th> {/* Usando el icono de teléfono */}
            </tr>
          </TableHeader>
          <TableBody>
        <tr>
        <td><b>Órgano Interno de Control Específico (OICE)</b></td>
          <td>Recibe quejas y reclamos por
          incumplimiento de las obligaciones de las personas servidoras
          públicas y es responsable de su seguimiento. El OIC depende de la
          Secretaría de la Función Pública, cuyo objetivo es promover una
          cultura de transparencia en el gobierno, rendición de cuentas, lucha
          contra la corrupción y el desempeño eficiente de las instituciones públicas.</td>
          <td>
            De manera presencial:
            {'\n'}
          •   Avenida Insurgentes Sur #1735, PB Módulo 3, Col. Guadalupe Inn, CP 01020, Alcaldia Álvaro Obregón, Ciudad de México.
            {'\n'}
            {'\n'} 
             Vía correspondencia a:
            {'\n'}
            • Unidad de Denuncias e Investigaciones de la Secretaría de la Función Pública - Av. Insurgentes Sur #1735, Piso 2, Ala Norte, Colonia Guadalupe Inn, CP 01020, Alcaldia Álvaro Obregón, Ciudad de México.
            {'\n'} 
            {'\n'}
            • Sistema Integral de Denuncias Ciudadanas (SIDEC) en la liga <a href='https://sidec.funcionpublica.gob.mx'>https://sidec.funcionpublica.gob.mx</a>  las 24 horas del día, los 365 días del año.
            {'\n'} 
          </td>
        </tr>
      </TableBody>
        </Table>
      </TableWrapper>

      <Wrapper>
        <Description>
        Solicitudes de Información
        </Description>
      </Wrapper>
      <TableWrapper>
        <Table>
          <TableHeader>
            <tr>
              <th>Mecanismo<Icon color="#285C4D"><FaBuilding /></Icon></th>
              <th>Casos que atiende<Icon color="#285C4D"><FaUser /></Icon></th>
              <th>Medios de recepción <Icon color="#285C4D"><FaPhone /></Icon></th> {/* Usando el icono de teléfono */}
            </tr>
          </TableHeader>
          <TableBody>
        <tr>
          <td><b>Unidad de Transparencia (UT)</b></td>
          <td>Realiza las gestiones necesarias en la CONAFOR a fin de facilitar el acceso a la información, siendo esta unidad el vinculo entre la institución como sujeto obligado y la persona solicitante.</td>
          <td>
          • Personalmente en oficinas centrales y Promotorías de Desarrollo Forestal, estatal o local, de la CONAFOR.
            {'\n'} 
            {'\n'} 
          • Página web: <a href='https://www.plataformadetransparencia.org.mx/'>www.plataformadetransparencia.org.mx </a>
            {'\n'}
            {'\n'} 
            • Correo electrónico: <a href="mailto:unidadtransparencia@conafor.gob.mx">unidadtransparencia@conafor.gob.mx</a>
            {'\n'}
          </td>
        </tr>
      </TableBody>
        </Table>
        
      </TableWrapper>
      <Wrapper>
        <Description>
          Resolución de dudas
        </Description>
      </Wrapper>
      <TableWrapper>
        <Table>
          <TableHeader>
            <tr>
              <th>Mecanismo<Icon color="#285C4D"><FaBuilding /></Icon></th>
              <th>Casos que atiende<Icon color="#285C4D"><FaUser /></Icon></th>
              <th>Medios de recepción <Icon color="#285C4D"><FaPhone /></Icon></th> {/* Usando el icono de teléfono */}
            </tr>
          </TableHeader>
          <TableBody>
        <tr>
          <td><b>Servicios de Información y Atención Ciudadana (SIAC)</b></td>
          <td>Brinda
          atención y respuesta oportuna a la ciudadanía, resolviendo dudas y
          ofreciendo información general sobre el Programa de Desarrollo
          Forestal Sustentable para el Bienestar y otras actividades de la
          CONAFOR (concursos, convocatorias, conferencias, exposiciones, entre otras).</td>
          <td>
    • Oficina de CONAFOR ubicada en Periférico Poniente #5360, edificio "C", Colonia San Juan de Ocotán, Código Postal 45019, Zapopan, Jalisco
    {'\n'}
    {'\n'}
    • 32 oficinas de Promoción del Desarrollo Forestal ubicadas en cada entidad estatal y 84 oficinas Locales de Promoción del Desarrollo Forestal.
    {'\n'}
    {'\n'}
    • Correo electrónico: <a href="mailto:conafor@conafor.gob.mx">conafor@conafor.gob.mx</a>
    {'\n'}
    {'\n'}
    • Telefónico:
    {'\n'} 
    <a href="tel:800 694 01 27">800 694 01 27</a>
    {'\n'} 
    {'\n'} 
    • Redes Sociales: 
    {'\n'} 
    <a href="https://twitter.com/CONAFOR"><Icon color="black"><FaXTwitter /></Icon></a> 
    {' '}
    <a href="https://www.facebook.com/CONAFOR.Central/"><Icon color="black"><FaFacebook /></Icon></a> 
</td>

        </tr>
      </TableBody>
        </Table>
        
      </TableWrapper>
    </>
  );
};

export default QuejasYDenuncias;
